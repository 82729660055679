type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20250214.10',
	commit: '8efb707'
};

export { releaseInfo };

