/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Blueprint, BlueprintData, Kitchen } from '@rta/rta-blueprint-library';
import { useContext, useEffect } from 'react';

import { NavType } from '@state';
import { SaveDirtyKitchenLocally } from '@features/customer/helpers';
import { AppContext, DesignActionTypes } from '@context';
import { getCustomerBlueprint, getCustomerBlueprints } from '@external';

export type LoadCustomerDesignsParams = {
	appNav: NavType;
}
// eslint-disable-next-line max-lines-per-function
export const useLoadCustomerDesigns = (params: LoadCustomerDesignsParams): void => {
	const { getAccessTokenSilently, user } = useAuth0();
	const { appState: { customerDesigns }, appDispatch: dispatch } = useContext(AppContext);
	const { appNav } = params;
	const { email, isLoading } = customerDesigns;

	const navigate = useNavigate();

	// no email, no designs
	useEffect(() => {
		if (appNav.email === null || appNav.email === '') {
			dispatch({ type: DesignActionTypes.clearCustomerDesigns, payload: null });
			if (appNav.email) navigate(`/${appNav.appMode}`);
			// console.log('render:clearCustomerDesigns', email, appNav.email, customerDesigns);
		}
	}, [appNav]);

	// load customer designs
	useEffect(() => {
		const load = async () => {
			// don't run if no nav email or already loading
			if (appNav.email === null || isLoading) {
				return;
			}

			if (appNav.email === email) {
				// return if no designs exist
				if (customerDesigns.designs.length === 0) return;

				// fix navigation if not already fully qualified
				// const [first] = customerDesigns.designs;
				// const bpNavId = appNav.designId ?? first.blueprint.id;
				// const numKitchens = (first.blueprint).kitchens.length;
				// const bpNavVersion = appNav.designVersion ?? (first.blueprint).kitchens[numKitchens - 1].version;

				// if (appNav.designVersion === null || appNav.designId === null) navigate(`/${appNav.appMode}/${appNav.email}/${bpNavId}/${bpNavVersion}`);
				return;
			}

			// console.log('render:loadCustomerDesigns', email, appNav.email, isLoading);
			let firstBp: Blueprint | null = null;

			dispatch({ type: DesignActionTypes.loadBlueprintsStart, payload: appNav.email });
			// console.log('loadCustomerDesigns:appNav', appNav);
			const submissionId = appNav.options?.submissionId ?? appNav.designId;
			if (appNav.appMode === 'v' && submissionId === null) {
				// appMode 'r' requires a submissionId, revert to default route if not available
				navigate('/');
				return;
			}

			const [blueprints, error] = appNav.appMode === 'v'
				? await getCustomerBlueprint({ email: appNav.email, submissionId: submissionId ?? '', getAccessTokenSilently })
				: await getCustomerBlueprints({ email: appNav.email, getAccessTokenSilently });
				// load autosaved entries for customer

			const autosaveEntries: Record<string, SaveDirtyKitchenLocally> = {};
			for (let i = 0; i < localStorage.length; i++) {
				const key = localStorage.key(i);
				if (key?.startsWith('autosave-')) {
					const rawValue = localStorage.getItem(key);
					const value: SaveDirtyKitchenLocally | null = rawValue ? JSON.parse(rawValue) : null;
					if (value && value.blueprintId && value.customer === appNav.email) autosaveEntries[value.blueprintId] = value;
				}
			}

			if (error) {
				// eslint-disable-next-line no-warning-comments
				// TODO: revisit this, the API call should be returning a 404, not 500 when not found
				// dispatch({ type: DesignActionTypes.loadBlueprintsFailure, payload: null });
				// return;
			} else {
				(blueprints as Array<BlueprintData>).forEach((blueprintData) => {
					try {
						const newBp = new Blueprint({ dataIn: blueprintData, userEmail: user?.email });
						const existingDraft = newBp.kitchens.findVersion(null);
						let version = existingDraft || appNav.designVersion === null ? null : Number(appNav.designVersion);
						let isDirty = false;

						// is there a draft saved locally? if so then clear any drafts from pulls BP
						// and load the draft into the BP
						const draftKitchen = autosaveEntries[newBp.id];
						if (draftKitchen) {
							isDirty = true;
							version = null;
							// console.log('draftKitchen1:', draftKitchen, existingDraft, newBp.kitchens);
							if (existingDraft) {
								// console.log('loadCustomerDesigns:delete-existing:before', existingDraft.id, newBp.kitchens.serialize());
								newBp.kitchens.delete(existingDraft.id);
								// console.log('loadCustomerDesigns:delete-existing:after', removed, newBp.kitchens.serialize());
							}
							const k = newBp.kitchens.newKitchen(draftKitchen.data);
							k.isLocal = true;
							// console.log('draftKitchen3:', draftKitchen, existingDraft, newBp.kitchens);
							delete autosaveEntries[newBp.id];
						} else if (existingDraft === null) {
							const kv = newBp.kitchens.newVersionFromLatest();
							if (kv) kv.isTemp = true;
						}
						// console.log('loadCustomerDesigns:2', { draftKitchen, existingDraft, version, isDirty, kitchens: newBp.kitchens });

						// don't use the initial system version, create a new version based on the system version to edit.
						// newBp.kitchens.newVersionFromLatest();

						if (firstBp === null) firstBp = newBp;
						dispatch({
							type: DesignActionTypes.addBlueprint,
							payload: { blueprint: newBp, version, isDirty }
						});
					} catch (e) {
						console.warn('error processing blueprint', e);
						// dispatch({ type: DesignActionTypes.loadBlueprintsFailure });
					}
				});
			}

			// load any remaining autosave entries
			Object.values(autosaveEntries).forEach((value) => {
				const newBp = new Blueprint({ userEmail: user?.email, dataIn: { id: value.blueprintId, email: appNav.email ?? 'SYSTEM', kitchens: [value.data] }});
				newBp.kitchens[0].isLocal = true;
				if (firstBp === null) firstBp = newBp;
				// newBp.kitchens.newKitchen(value.data);
				const version = appNav.designVersion === null ? null : Number(appNav.designVersion);
				dispatch({
					type: DesignActionTypes.addBlueprint,
					payload: { blueprint: newBp, version, isDirty: true }
				});
			});


			if (firstBp !== null) {
				const bpNavId = appNav.designId ?? (firstBp as Blueprint).id;
				const bpNavDraft: Kitchen | null = (firstBp as Blueprint).kitchens.getActiveVersion();
				const bpNavLatest: Kitchen | null = (firstBp as Blueprint).kitchens.getLatestVersion();
				const bpNavVersion = appNav.designVersion ?? (bpNavDraft?.version === null ? bpNavDraft.baseVersion : bpNavLatest?.version ?? '');
				const useDraft = bpNavDraft?.version === null || bpNavDraft?.version === bpNavLatest?.version;

				console.log('----------------------------');
				console.log('appNav:', { firstBp, bpNavDraft, bpNavLatest });
				console.log('appNav:', {
					urlVersion: appNav.designVersion,
					draft: { base: bpNavDraft?.baseVersion, version: bpNavDraft?.version },
					latest: { base: bpNavLatest?.baseVersion, version: bpNavLatest?.version	},
					bpNavVersion,
					useDraft
				});
				console.log('----------------------------');
				// console.log('appNav:', appNav.designId, appNav.designVersion, appNav.options, `/${appNav.appMode}/${appNav.email}/${bpNavId}/${bpNavVersion}`);
				navigate(`/${appNav.appMode}/${appNav.email}/${bpNavId}/${bpNavVersion ?? ''}${useDraft ? '?draft' : ''}`);
			}

			dispatch({ type: DesignActionTypes.loadBlueprintsSuccess, payload: null });
		};

		load();
	}, [appNav, email]);
};
